<template>
  <div>
    <v-card class="pa-2 mb-2">
      <v-row>
        <v-col cols="12" sm="2">
          <v-text-field
            hide-details
            outlined
            clearable
            dense
            label="Keyword"
            :disabled="isBusy"
            v-model="searchFilters.keyword"
            @keydown.enter="update"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            hide-details
            outlined
            dense
            label="Active"
            v-model="searchFilters.active"
            @keydown.enter="update"
            :disabled="isBusy"
            :items="activeOptions"
            item-text="option"
            item-value="value"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-checkbox
            class="mt-0"
            v-model="searchFilters.include_trashed"
            :label="'Include deleted'"
            outlined
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn
            outlined
            block
            height="40"
            :loading="isBusy"
            @click="update"
          >Search</v-btn>
        </v-col>
        <v-col cols="12" sm="2" v-if="isSuperAdmin">
          <v-btn
            outlined
            block
            height="40"
            :loading="isBusy"
            @click="add"
          ><v-icon>mdi-plus</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-card>

    <PaginationCardV2
      :page="searchFilters.page"
      :totalRecords="paginationData.count"
      :totalPages="paginationData.pages"
      :limit="searchFilters.limit"
      :currentPageTotal="(paginationData.data) ? paginationData.data.length : 0"
      :loading="isBusy"
      @updatedPagination="updatedPagination"
      :no-skeleton="false"
      :limit-options="[]"
      :hide-paginations-buttons="true"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Username</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Active</th>
            <th>2FA</th>
            <th>User Type</th>
            <th>Created</th>
            <th>Last Password Change</th>
          </tr>
        </thead>

        <v-slide-x-transition tag="tbody" hide-on-leave group>
          <tr v-if="paginationData.data && paginationData.data.length === 0" key="nodata">
            <td colspan="100%" class="text-center">
              No Auto Attendants
            </td>
          </tr>
          <tr
            v-for="item in paginationData.data"
            :key="item.id"
            :class="{ 'deleted-row': item.deleted_at != null }"
          >
            <td>{{item.id}}</td>
            <td>
              <router-link :to="{ name: 'AdminUserDetails', params: { userId: item.id}, query:{admin:1}}"><span class="font-weight-medium">{{
                  item.username
                }}</span>
                <v-icon v-if="item.roles.includes('super-admin')">mdi-shield-account</v-icon>
              </router-link>
            </td>
            <td>{{ item.firstname }}</td>
            <td>{{ item.lastname }}</td>
            <td>
              <v-icon
                v-if="item.active_at"
                color="success"
                class="mr-2"
              >mdi-check</v-icon>
              <v-icon
                v-if="!item.active_at"
                color="error"
                class="mr-2"
              >mdi-cancel</v-icon>
            </td>
            <td>
              <v-icon
                v-if="item.twofactor_enabled_at != null"
                color="success"
                class="mr-2"
              >mdi-check</v-icon>
              <v-icon
                v-if="item.twofactor_enabled_at == null"
                color="error"
                class="mr-2"
              >mdi-cancel</v-icon>
            </td>
            <td>{{ item.user_type }}</td>
            <td>
              <LocalDateTime
                :value="item.created_at"
                hide-tooltip
              />
            </td>
            <td>
              <LocalDateTime
                :value="item.password_updated_at"
                hide-tooltip
              />
            </td>
          </tr>
        </v-slide-x-transition>
      </v-simple-table>
    </PaginationCardV2>
    <AddAdminUserDialog
      v-model="showAddAdminUserDialog"
      />
  </div>
</template>

<script>
import PaginationCardV2 from "../../../components/cards/templates/PaginationCardV2.vue";
import LocalDateTime from '@/components/pieces/LocalDateTime';
import apiMixin from "../../../mixins/apiMixin";
import paginationComponentMixin from "../../../mixins/paginationComponentMixin";
import refreshListenerMixin from "../../../mixins/refreshListenerMixin";
import AddAdminUserDialog from "../../../components/dialogs/User/AddAdminUserDialog.vue";
import dialogMixin from "../../../mixins/dialogMixin";

export default {
    name: 'AdminUserList',
    mixins: [apiMixin, paginationComponentMixin, refreshListenerMixin, dialogMixin],
    data: () => ({
      showAddAdminUserDialog:false,
      searchFilters: {
        id: null,
        keyword:null,
      },
      paginationData:[],
      busy: false,
      activeOptions: [
        { option: 'Any', value: null },
        { option: 'Active', value: true},
        { option: 'Not active', value: false},
      ],
    }),
    components: {
      AddAdminUserDialog,
        LocalDateTime,
        PaginationCardV2,
    },
    computed: {
      isBusy: function( ){
        return this.loading || this.busy;
      },
      isSuperAdmin: function(){
        return this.$store.state.user.user_type == 'admin' && this.$store.state.user.user_roles && this.$store.state.user.user_roles.includes('super-admin');
      }
    },
    methods: {
      reset() {
        this.localFilter.id = null;
        this.localFilter.null = null;
      },
      updatedPagination(data){
        this.searchFilters.page = data.page;
        this.searchFilters.limit = data.limit;
      },
      add( ){
        this.showAddAdminUserDialog = true;
      },
      async update() {
          this.busy = true;
          let params = this.searchFilters;

        for (const [key, value] of Object.entries(params)) {
          if(value == null || value == undefined){
            delete params[key];
          }
        }
        params.user_type = 'admin';

        let queryString = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');

          const response = await this.Api.send('get','admin/users/list?'+queryString);
          this.busy = false;
          if (response.success) {
              this.paginationData = {
                data: response.data.data,
                count: response.data.count,
                pages: response.data.pages
              };
          } else {
              this.updateFailed(response.error, response.errorDetail);
          }
      },
    },
    created() {
        //this.update();
    },
};
</script>
<style>
.deleted-row {
  background-color: #ffcccc; /* Light red, adjust the color as needed */
  text-decoration:line-through;
}
</style>