<template>
  <div class="container">
    <v-row dense>
      <v-col cols="4">
        <v-alert type="warning" transition="fade" outlined class="mt-4 mb-0">I'm a presentable alert message.</v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/**
 * This is just a page to hold some UI elements that can be used within the system.
 * Generally something that I find is presentable that I'm converned might be lost if its remove.
 * Or a place to refer to usefull style elements
 */
export default {
    name: 'AdminUiKit',

};
</script>
